import React from "react";
import BasicLayout from "../../OPBasicLayout";
const MillionsTournament = (props) => {
  return (
    <BasicLayout>
        <h1 style={{color: "#ffffff", textAlign: "center", marginTop: "5em"}}>Millions Tournaments InProgess....</h1>
    </BasicLayout>
  );
};

export default MillionsTournament;
