import React from 'react'
import { Image as Images } from '../Images'


const GGcare = () => {
    return (
        <>
            <div className="ggcare">
                <div className="">
                    <div className='flipout-header mt-5'>
                        <h1>GGCare</h1>
                    </div>
                    <div className="container pt-4 pb-4">
                        <img src={Images.ggcare} width="100%" height="auto" alt="" />
                    </div>
                </div>
                <div className="">
                    <div className='flipout-header mt-5'>
                        <h1>GGCheers</h1>
                    </div>
                    <div className="container pt-4 pb-4">
                        <img src={Images.ggcheers} width="100%" height="auto" alt="" />
                    </div>
                    <div className=" pt-4 pb-4">
                        <img src={Images.ggcheers1} alt="" width="100%" height="auto" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GGcare