import React from "react";
import Carousel from "../../Components/Carousel/Carousel";
import Promotions from "../../Components/Promotions";
import AwardAndPokerNetwork from "../../Components/AwardAndPokerNetwork";
import Whyplay from "../../Components/Whyplay";
import ThisMonth from "../../Components/ThisMonth";
import Tournaments from "../../Components/Tournaments";
import BasicLayout from "../../OPBasicLayout";
const HomePage = (props) => {
    const {history} = props
  return (
    <BasicLayout>
      <Carousel history={history}/>
      <AwardAndPokerNetwork />
      <Whyplay />
      <Promotions />
      <Tournaments />
      <ThisMonth />
    </BasicLayout>
  );
};

export default HomePage;
