import { Card} from "antd";
import React from "react";
import {  cardStyle, headerTitle, headerDescription } from "./style";

const CardComponent = (props) => {
  const { url, heading, description, index } = props;
  return (
    <Card hoverable style={cardStyle} index={index}>
      <div style={{ display: "flex" }}>
        <div style={{ height: "auto", overflow: "auto" }}>
          <img
            alt={heading}
            style={{ float: "right", height: "auto" }}
            src={url}
          />
        </div>
        <div
          style={{ position: "absolute", padding: "30px 20px", width: "350px" }}
        >
          <p style={headerTitle}>{heading}</p>
          <p style={headerDescription}>{description}</p>
        </div>
      </div>
    </Card>
  );
};

export default CardComponent;
