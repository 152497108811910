import React from 'react'
import { Image as Images } from './Images'

const Promotions = () => {
    let arr = [
        {
            Img: Images.promotion1,
            title: 'Daily Flipout',
            title1: "$100,000"
        },
        {
            Img: Images.promotion2,
            title: 'MILLION$',
            title1: "Biggest Weekly series"
        },
        {
            Img: Images.promotion3,
            title: 'GG MASTERS',
            title1: "$3,000,000 GTD"
        },
        {
            Img: Images.promotion4,
            title: 'July Giveaway',
            title1: "$10M"
        },
        {
            Img: Images.promotion5,
            title: 'GG Daily Freebie',
            title1: "Claim now!"
        },
        {
            Img: Images.promotion6,
            title: 'HoneyMoon',
            title1: "Claim instant reward!"
        },
        {
            Img: Images.promotion7,
            title: 'Welcome with Million$',
            title1: "$100 in Rewards"
        },
        {
            Img: Images.promotion8,
            title: 'ROSHAMB!',
            title1: "GET NOW!"
        },
    ]
    return (
        <>
            <div className='promotiona-header'>
                <h1>CURRENT PROMOTIONS</h1>
            </div>
                {/* <CarouselComponent> */}
                <div className="container pt-4 pb-4">
                    <div className='row  '>
                        {arr?.map((e, i) => {
                            return (
                                <div className='col-12 co-sm-6 col-md-4 col-lg-3 col-xl-3 pt-3 pb-3 d-flex justify-content-center align-item-center'>
                                        {/* <div className="container"> */}
                                    <div class="card promotion-card row mx-0">

                                            <div className=' p-0'>
                                                <img src={e.Img} className="card-img-top position-relative" alt="..." />
                                                <div className="card-body position-absolute promotion-card-body bottom-0 ">
                                                    <p className="card-text text-light ">{e.title}</p>
                                                    <p className="card-text text-light ">{e.title1}</p>
                                                </div>
                                            </div>
                                        </div>
                                    {/* </div> */}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* </CarouselComponent> */}
        </>
    )
}

export default Promotions