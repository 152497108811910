import React from 'react'
import DailyFlipout from '../../Components/FlipoutComponents/DailyFlipout'
import Footer from '../../Components/Footer'
import GGcare from '../../Components/FlipoutComponents/GGcare'
import Header from '../../Components/Header'
import Aboutggcare from '../../Components/FlipoutComponents/Aboutggcare'
import Faq from '../../Components/FlipoutComponents/Faq'

const FlipoutPage = () => {
  return (
    <div>
      <Header />
      <div style={{ marginTop: "90px" }}>
        <DailyFlipout />
        <GGcare/>
        <Aboutggcare/>
        <Faq/>
      </div>
      <Footer />
    </div>
  )
}

export default FlipoutPage