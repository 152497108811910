import React from "react";
import { Result, Button } from "antd";
import BasicLayout from "../OPBasicLayout";

const onReload = () => window.location.reload();
const PageNotFound = () => (
  <BasicLayout>
    <div style={{ marginTop: "10em" }}>
      <Result
        title={"Page Not Found"}
        subTitle={
          "Sorry, the doesn't exist, try again or contact the administrator."
        }
        extra={
          <Button type="primary" onClick={onReload}>
            Try again
          </Button>
        }
      />
    </div>
  </BasicLayout>
);
export default PageNotFound;
