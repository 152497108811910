export const fullScheduleBtn = {
  padding: "5px 45px",
  gap: "10px",
  background: "linear-gradient(227.72deg, #0E6EFF 6.9%, #185ABD 108.64%)",
  borderRadius: "14px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "15px",
  letterSpacing: "0.444444px",
  color: " #FFFFFF",
  flex: "none",
  border: "none",
};
export const moreInfoBtn = {
  padding: "5px 45px",
  gap: "10px",
  background:
    "linear-gradient(270deg, rgba(14, 110, 255, 0) 0%, #74A9F9 242.69%)",
  borderRadius: "14px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "15px",
  letterSpacing: "0.444444px",
  color: " #FFFFFF",
  flex: "none",
  border: "none",
};
export const tournamentContent = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  letterSpacing: "0.444444px",
  color: "#FFFFFF",
};
export const tournamentContentHeading = {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "25px",
    letterSpacing: "0.444444px",
    color: "#FFFFFF",
  };

export const childContentContainer = {
    display: "flex",
    justifyContent:'center',
    // alignItems:'center',
    padding:'5rem 0'
}