import React from "react";
import {Carousel} from "3d-react-carousal";

const CarouselComponent = (props) => {
  const { children, slides } = props;
  return (
    <div style={{minHeight: "400px"}}>
    <Carousel
      slides={slides}
      autoplay={true}
      interval={5000}
      arrows={true}
     >
      {children}
      </Carousel>
    </div>

  );
};

export default CarouselComponent;
