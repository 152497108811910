import React from "react";
import BasicLayout from "../../OPBasicLayout";
const MonthlyMillionsGiveaway = (props) => {
  return (
    <BasicLayout>
        <h1 style={{color: "#ffffff", textAlign: "center", marginTop: "5em"}}>Monthly Millions Giveaway InProgess....</h1>
    </BasicLayout>
  );
};

export default MonthlyMillionsGiveaway;
