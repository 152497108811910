import React from "react";
import {  Route, Redirect } from "react-router-dom";
import routes from "./URLs";

export const BASE_ROUTE = process.env.BASE_ROUTE_PREFIX || "/ocean-poker/";
const PrivateRouteWithStore = ({ component: Component, ...rest }) => {
  const landingPage = () => {
    try {
      const route = routes.HOME_PAGE;
      return route;
    } catch (error) {
      console.error("error in landingPage", error);
      return false;
    }
  };
  if (rest?.path === "/" || rest?.path === BASE_ROUTE) {
    const landingPageRoute = landingPage();
    return (
      <Route
        {...rest}
        render={(props) => <Redirect from="/" to={landingPageRoute} />}
      />
    );
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRouteWithStore;
