import React from 'react'

const Faq = () => {
    return (
        <>
            <div className="">
                <div className='flipout-header mt-5'>
                    <h1>GGCare & GGcheers Faq</h1>
                </div>
                <div className="container faq-body py-5">
                    <div className="">
                        <h1> What are the exact conditions that cause GGCare or GGCheers to occur?</h1>
                        <p>The specific details vary by game and often change based on numerous outside factors. As such, the exact conditions that trigger these events cannot be specifically enumerated. However, our team of game experts continuously look for any especially lucky or unlucky situations throughout gameplay to add more trigger points.</p>
                    </div>
                    <div className="">
                        <h1>What determines the amount of stack I get to enter the ThanksGG Flipout tournament?</h1>
                        <p>The starting stack size will vary depending on the pot size and severity of the situation. GGPoker Network will optimize the formula continuously to provide a fair flipout to all players with different initizing situations.</p>
                    </div>
                    <div className="">
                        <h1>What happens if it happens more than once?</h1>
                        <p>If you have two or more qualifying hands, you will accumulate additional tournament chips each time, increasing your starting chips.</p>
                    </div>
                    <div className="">
                        <h1>Where can I check my GGCare & GGCheers hands?</h1>
                        <p>You can check your GGCare & GGCheers hands in PokerCraft under My Tournaments, on the PokerCraft Timeline , or in the ThanksGG Flipout Tournament lobby.</p>
                    </div>
                    <div className="">
                        <h1>Flipout Tournament?</h1>
                        <p>
                            A Flipout Tournament is a type of automated tournament where each hand, every player is pushed all-in. Within seconds, a winner is determined. Players don’t even need to be online while the tournament takes place – there are no decisions to be made once the tournament starts.
                            <br />
                            <br />
                            GGCare & GGCheers run everyday from 00:00:00 to 23:59:59, and the ThanksGG Flipout tournament will begin each day at 00:45:00 (UTC -8)
                        </p>
                    </div>
                    <div className="">
                        <h1>How do I get my money from GGCare & GGCheers?</h1>
                        <p>Rewards will be paid out in C$ as soon as the ThanksGG Flipout tournament is over. You can check results in the My Tournaments tab and on the PokerCraft timeline.</p>
                    </div>
                    <div className="">
                        <h1>Which games can I play to get GGCare & GGCheers benefits?</h1>
                        <p>Games that are eligible for this promotion are all cash games including Hold’em, Omaha, All-In or Fold, Rush & Cash, Spin & Gold, and Short Deck, as well as tournaments.</p>
                    </div>
                </div>
            </div>
            <div className="Terms py-5">
                <div className="container">
                    <h1>Terms & Conditions</h1>
                    <ul>
                        <li>Players must be aged 18 and above to participate in this promotion</li>
                        <li> If any players fall under suspicion of fraudulent activity, GGNetwork has the right to investigate and remove the players once it’s confirmed.</li>
                        <li>The promotion terms and conditions are subject to the site terms and conditions, which can be found here.</li>
                        <li>GGNetwork reserves the right to modify or suspend the promotion at any time. GGNetwork standard rules apply.</li>
                    </ul>
                </div>
            </div>
        </>

    )
}
export default Faq