import React from "react";
import BasicLayout from "../../OPBasicLayout";
const FishBuffet = (props) => {
  return (
    <BasicLayout>
        <h1 style={{color: "#ffffff", textAlign: "center", marginTop: "5em"}}>Fish Buffet InProgess....</h1>
    </BasicLayout>
  );
};

export default FishBuffet;
