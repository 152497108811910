import React from "react";
import { networkHeading, headingContent } from "../AwardAndPokerNetwork/style";
import {
  fullScheduleBtn,
  moreInfoBtn,
  tournamentContent,
  tournamentContentHeading,
  childContentContainer,
} from "./style";
import { Image } from "../Images";
import { Button } from "antd";
const CarouselComponent = (props) => {
  return (
    <>
      <div style={networkHeading}>
        <p style={headingContent}>Tournaments</p>
      </div>
      <div style={childContentContainer} className='container'>
        <div>
          <div>
            <img alt={"TrournamentImg1"} src={Image.TrournamentImg1} />
          </div>
          <div style={{marginTop: "-80px", zIndex: "9"}}>
            <img alt={"TrournamentImg1"} src={Image.TrournamentImg2} />
          </div>
        </div>
        <div>
          <div>
            <p style={tournamentContentHeading}>WSOP ONLINE Circuit - July </p>
            <p style={tournamentContent}>
              World Series of Poker has been the most
              trusted name in the game. WSOP.com continues this legacy, yet
              strikes the proper balance between professional-grade and
              accessible.
            </p>
          </div>
          <div style={{display: "flex", gap: "30px"}}>
            <div>
              <Button style={fullScheduleBtn}>Full Schedule</Button>
            </div>
            <div>
              <Button style={moreInfoBtn}>More information</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselComponent;
