import { configureStore } from '@reduxjs/toolkit'

export default configureStore({
  reducer: {},
})

// import { configureStore, applyMiddleware, compose } from '@reduxjs/toolkit';
// import rootReducer from '../reducers/rootReducer';
// import createSagaMiddleware from 'redux-saga';
// import rootSaga from '../../sagas/rootSaga';
// import { REACT_APP_IMAGE_FILE_MAX_SIZE } from '../../common/config';

// const sagaMiddleware = createSagaMiddleware();
// const componseEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const initialState = window && process.env.NODE_ENV !== 'development' ? window.INITIAL_STATE 
//     : {common:{PORT:process.env.PORT || 3000,REACT_APP_RESTAPI_URL: process.env.REACT_APP_RESTAPI_URL, 
//         REACT_APP_IMAGE_FILE_MAX_SIZE: process.env.REACT_APP_IMAGE_FILE_MAX_SIZE || REACT_APP_IMAGE_FILE_MAX_SIZE }};
// const store = configureStore(rootReducer, initialState,componseEnhancers(applyMiddleware(sagaMiddleware)));
// sagaMiddleware.run(rootSaga)

// export default store;