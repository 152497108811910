import React from "react";
import BasicLayout from "../../OPBasicLayout";
const MillionsGiveaway = (props) => {
  return (
    <BasicLayout>
        <h1 style={{color: "#ffffff", textAlign: "center", marginTop: "5em"}}>Millions Giveaway InProgess....</h1>
    </BasicLayout>
  );
};

export default MillionsGiveaway;
