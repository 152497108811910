import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.min.css';
import store from './Store/store'
import { Provider } from 'react-redux'
import Route from "./Route"

ReactDOM.render(
    <Provider store={store}>
      <Route />
    </Provider>,
  document.getElementById("root")
)
