import React from 'react'
import { Image as Images } from './Images'

const Header = () => {
    return (
        <>
            <div className='main_header'>
                <div className='row h-100 align-items-center'>
                    <div className='logo col-12 col-sm-6'>
                        <img src={Images.header_logo} alt='logo' />
                    </div>
                    <div className='header_right_buttons col-12 col-sm-6 '>
                        <ul className='list-inline mb-0'>
                            <li className='list-inline-item'>
                            <button className='header-join-btn'>Join</button>
                            </li>
                            <li className='list-inline-item'>
                            <button className='download-btn'>Download <img src={Images.download} alt='download' /></button>

                            </li>
                        </ul>
                    
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header