import React from 'react'
import { Image as Images } from './Images'

const Whyplay = () => {

    const data = [{
        img: Images.Whyplay1,
        title: "Security",
        title2: "We have guarranteed security & safety for all users"

    },
    {
        img: Images.Whyplay2,
        title: "User Base",
        title2: "We connect Poker players from throughout the globe"

    },
    {
        img: Images.Whyplay3,
        title: "Transactions",
        title2: "Instantly access and withdraw your funds "

    }]
    return (
        <>
            <div className='promotiona-header mt-5'>
                <h1>Why Play at Oceanpoker?</h1>
            </div>
            <div className='row p-4 w-100'>
                {data.map((e, i) => {
                    return (
                        <div className='col-12 co-sm-6 col-md-4 col-lg-4'>
                            <div class="container w-50">
                                <div className='p-0'>
                                    <img src={e.img} width="100" height="100" className="card-img-top " alt="..." />
                                    <div className="card-body  text-center bottom-0 whyplay-card ">
                                        <p className="card-text text-light ">{e.title}</p>
                                        <p className="card-text text-light ">{e.title2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <hr className='divider' />
            <div className='row stepper d-flex align-items-center justify-content-center mb-3 w-100 '>
                <div className='col-3 col-md-3 card bg-transparent border-0 position-relative'>
                    <img src={Images.stepper}  className="" alt="" />
                    <p className='position-absolute  stepper-head'>How to play?</p>
                </div>
                <div className='col-2 col-md-2 p-0'>
                    <h1 className='stepper-title'>1.Download</h1>
                </div>
                <div className="col-1 col-md-1 p-0 ">
                    <img src={Images.stepper1} width='13px' height='67px' alt="" />
                </div>
                <div className='col-2 col-md-2 p-0 '>
                    <h1 className='stepper-title'>2.Create Free Account</h1>
                </div>
                <div className="col-1 col-md-1 p-0 ">
                    <img src={Images.stepper1} width='13px' height='67px' alt="" />
                </div>
                <div className='col-2 col-md-2 p-0'>
                    <h1 className='stepper-title' style={{textShadow:"0px 0px 60px #98C1FF"}}>3.Join The Action!</h1>
                </div>
                <div className='col-1 col-md-1'></div>
            </div>

        </>
    )
}

export default Whyplay