import React, {  Component } from "react";
import { Button, Card, Descriptions } from "antd";
import { loggerButtonDiv, loggerCardLayout, loggerDescription } from "./style";
import { WarningFilled } from "@ant-design/icons";
import BasicLayout from "../OPBasicLayout";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: {},
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const { isLogsEnabled, commonFailureAction } = this.props;
    this.setState({ hasError: error, errorInfo: info });
    if (isLogsEnabled) {
      commonFailureAction(error);
    }
  }

  handleReload = () => {
    window.location?.reload();
  };

  getLimitedText = (value) => {
    const str =
      value?.length > 200 ? value?.substring(0, 200) + " ...." : value;
    return str;
  };

  renderFallback = () => {
    const { errorInfo, hasError } = this.state;
    return (
      <BasicLayout>
        <div style={loggerCardLayout}>
          <Card
            type="inner"
            title={
              <h3>
                {" "}
                <WarningFilled style={{ color: "red" }} />{" "}
                {"Something went wrong...."}{" "}
              </h3>
            }
          >
            <>
              <Descriptions bordered>
                <Descriptions.Item label={"Error Message"}>
                  <div style={loggerDescription}>{hasError?.message}</div>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions bordered>
                <Descriptions.Item label={"Error Location"}>
                  <div style={loggerDescription}>
                    {this.getLimitedText(errorInfo?.componentStack)}
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </>
            <div style={loggerButtonDiv}>
              <Button type="primary" onClick={this.handleReload}>
                Reload
              </Button>
            </div>
          </Card>
        </div>
      </BasicLayout>
    );
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <>{this.renderFallback()}</>;
    }
    return children;
  }
}

export default ErrorBoundary;
