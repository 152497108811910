import header_logo from "../assets/Images/logo.svg";
import download from "../assets/Images/download.svg";
import pokerlogo1 from "../assets/Images/pokerlogo1.svg";
import pokerlogo2 from "../assets/Images/pokerlogo2.svg";
import pokerlogo3 from "../assets/Images/pokerlogo3.svg";
import begamble from "../assets/Images/begamble.svg";
import age from "../assets/Images/age.svg";
import mastercard from "../assets/Images/mastercard.svg";
import visa from "../assets/Images/visa.svg";
import privacy from "../assets/Images/privacy.svg";
import sigma from "../assets/Images/sigma.svg";
import carousel1 from "../assets/Images/carousel1.svg";
import carousel2 from "../assets/Images/carousel2.svg";
import carousel3 from "../assets/Images/carousel3.svg";
import carousel4 from "../assets/Images/carousel4.svg";
import carousel5 from "../assets/Images/carousel5.svg";
import cardPoker1 from "../assets/Images/cardPoker1.svg"
import cardPoker2 from "../assets/Images/cardPoker2.png"
import cardPoker3 from "../assets/Images/cardPoker3.png"
import TrournamentImg1 from "../assets/Images/tournamentImg1.svg"
import TrournamentImg2 from "../assets/Images/tournamentImg2.svg"
import promotion1 from "../assets/Images/promotion1.svg";
import promotion2 from "../assets/Images/promotion2.svg";
import promotion3 from "../assets/Images/promotion3.svg";
import promotion4 from "../assets/Images/promotion4.svg";
import promotion5 from "../assets/Images/promotion5.svg";
import promotion6 from "../assets/Images/promotion6.svg";
import promotion7 from "../assets/Images/promotion7.svg";
import promotion8 from "../assets/Images/promotion8.svg";
import Whyplay1 from "../assets/Images/whyplay1.svg"
import Whyplay2 from "../assets/Images/whyplay2.svg"
import Whyplay3 from "../assets/Images/whyplay3.svg"
import stepper from "../assets/Images/stepper.svg"
import stepper1 from "../assets/Images/stepper1.svg"
import month1 from "../assets/Images/month1.svg"
import month2 from "../assets/Images/month2.svg"
import month3 from "../assets/Images/month3.svg"
import month4 from "../assets/Images/month4.svg"
import month5 from "../assets/Images/month5.svg"
import month6 from "../assets/Images/month6.svg"
import DailyFlipout from "../assets/Images/DailyFlipout.png"
import ggcare from "../assets/Images/ggcare.png"
import ggcheers from "../assets/Images/ggcheer.png"
import ggcheers1 from "../assets/Images/ggcheer1.png"
import aboutgg1 from "../assets/Images/aboutgg1.svg"
import aboutgg2 from "../assets/Images/aboutgg2.svg"
import aboutgg3 from "../assets/Images/aboutgg3.svg"
import aboutgg4 from "../assets/Images/aboutgg4.png"
import ThanksGGT from "../assets/Images/ThanksGGT.png"



export let Image={
    header_logo,
    download,
    pokerlogo1,
    pokerlogo2,
    pokerlogo3,
    begamble,
    age,
    mastercard,
    visa,
    privacy,
    sigma,
    carousel1,
    carousel2,
    carousel3,
    carousel4,
    carousel5,
    promotion1,
    promotion2,
    promotion3,
    promotion4,
    promotion5,
    promotion6,
    promotion7,
    promotion8,
    cardPoker1,
    cardPoker2,
    cardPoker3,
    TrournamentImg1,
    TrournamentImg2,
    Whyplay1,
    Whyplay2,
    Whyplay3,
    stepper,
    stepper1,
    month1,
    month2,
    month3,
    month4,
    month5,
    month6,
    DailyFlipout,
    ggcare,
    ggcheers,
    ggcheers1,
    aboutgg1,
    aboutgg2,
    aboutgg3,
    aboutgg4,
    ThanksGGT,
}