import React from "react";
import { Result } from "antd";
import BasicLayout from "../OPBasicLayout";
const AccessDenied = () => (
  <BasicLayout>
    <div style={{ marginTop: "10em" }}>
      <Result
        title={"Access Denied"}
        subTitle={
          "Sorry, you are not authorized to access, please contact the administrator."
        }
      />
    </div>
  </BasicLayout>
);

export default AccessDenied;
