export const networkHeading = {
  height: "75px",
  left: "0px",
  top: "0px",
  background: "#212121",
  boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.85)",
};
export const headingContent = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "75px",
  textAlign: "center",
  letterSpacing: "0.444444px",
  textTransform: "uppercase",
  color: "#FFFFFF",
};

export const networkCards = {
  marginTop: "5px",
  display: "flex",
  gridGap: "25px 12px",
  flexWrap: "wrap",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
};
export const awardcards = {
  display: "flex",
  gridGap: "20px",
  flexWrap: "wrap",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
};

export const awardImages = {
// boxShadow: "2.87591px 2.87591px 12.9416px -4.31387px rgba(0, 120, 225, 0.61), 4.31387px 4.31387px 37.3869px 1.43796px rgba(64, 157, 238, 0.13)",
borderRadius: "14.3796px",
float: "right", 
height: "auto" 
}