import React from 'react'
import { Image as Images } from '../Images'


const Aboutggcare = () => {
    let data = [
        {
            step: 'Step 1',
            img: Images.aboutgg1,
            title: 'Did you have an unfortunate loss? Did you have an unforgettable victory? GGCare and GGCheers will always be with you!',
        },
        {
            step: 'Step 2',
            img: Images.aboutgg2,
            title: 'Check the pop-up window to see your registration to the daily flip-out tournament. You will be automatically registered.',
        },
        {
            step: 'Step 3',
            img: Images.aboutgg3,
            title: 'Get a good night’s rest.When you wake up in the morning, a wonderful prize will be waiting for you!',
        }
    ]
    return (
        <div className="">
            <div className="">
                <div className='flipout-header mt-5'>
                    <h1>Let GGCare take the weight off your shoulders!</h1>
                </div>
                <div className="container pt-4 pb-2">
                    <div className="row">
                        {
                            data.map((e) => {
                                return (
                                    <>
                                        <div className="col-4 col-md-4 col-lg-4 pb-5">
                                            <div className="row mx-0 ">
                                                <div className="col-12 position-relative  aboutggcare-steps px-0">
                                                    <img src={Images.stepper} width='100%' height='auto' alt="" className='cover-fill' />
                                                    <p className="position-absolute aboutggcare-para">{e.step}</p>
                                                </div>
                                                <div className="col-12 aboutggcare-body px-0">
                                                    <div className="row mx-0">
                                                        <div className="col-12 px-0">
                                                            <img src={e.img} alt="" width='100%' height='auto' />
                                                        </div>
                                                        <div className="col-12 px-5">
                                                            <p className="">{e.title}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container-fluid thanksgg">
                    <div className="container py-5">
                        <img src={Images.ThanksGGT} className='thanksgg-img' width='auto' height='auto' alt="" />
                        <p className=''>Running Everyday at 00:45:00 (UTC -8)</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutggcare