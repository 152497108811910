import React from "react";
import { Layout } from "antd";
import "../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../assets/css/custom.css'
import FooterComponent from "../Components/Footer";
import HeaderComponent from "../Components/Header";

const { Content } = Layout;

class OPLayout extends React.Component {
  state = {
    collapsed: false,
    contentMargin: 253,
  };

  renderPageHeader = () => {
    return (
        <HeaderComponent />
    );
  };
  renderPageFooter = () => {
    return (
        <FooterComponent />
    );
  };
  renderContent = () => {
    const { children } = this.props;
    return (
      <div style={{marginTop: "90px"}}>
        <Content>{children}</Content>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderContent()}
        {this.renderPageFooter()}
      </>
    );
  }
}

export default OPLayout;
