import React from 'react'
import { Image as Images } from '../Images'


const DailyFlipout = () => {
    return (
        <div className="flipout">
            <div className="">
                <img src={Images.DailyFlipout} width="100%" height="auto" alt="" />
            </div>
            <div className="">
                <div className='flipout-header mt-5'>
                    <h1>DAILY $100,000 FLIPOUT</h1>
                </div>
                <p className='container pt-4 pb-4'>Had bad day at the tables? Things not go your way? Or did you have a great day, a seemingly unstoppable poker force?  Either way - enjoy a $100k Daily Flip Out in partnership with GGCare and GGCheers</p>
            </div>

        </div>
    )
}

export default DailyFlipout