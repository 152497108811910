import React from 'react'
import { Image as Images } from './Images'


const Footer = () => {
  return (
    <>
      <div className='first-footer'>
        <h1>Get in touch with us</h1>
        <p className="">support@oceanpoker.io</p>
      </div>
      <footer class="container-fluid footer w-100">
        <div className='container'>
          <p className="text-light footer_para"> &#169;All the games are powered by GGPoker. All mention of OceanBet Poker only used for marketing purposes.  </p>
          <div class="row h-100 pt-5 ">
            <div className="col-6 col-md-4 ">
              <img className='logo' src={Images.header_logo} alt="" />
            </div>
            <div className="col-6 col-md-4">
              <div className=' d-flex gap-3'>
                <img src={Images.pokerlogo1} alt="" />
                <img src={Images.pokerlogo2} alt="" />
                <img src={Images.pokerlogo3} alt="" />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="container">
                <ul className='mb-0 list-inline'>
                  <li className='list-inline-item'>
                    <img src={Images.visa} alt="" />
                  </li>
                  <li className='list-inline-item'>
                    <img src={Images.mastercard} alt="" />
                  </li>
                  <li className='list-inline-item'>
                    <img src={Images.privacy} alt="" />
                  </li>
                  <li className='list-inline-item'>
                    <img src={Images.age} alt="" />
                  </li>
                  <li className='list-inline-item'>
                    <img src={Images.begamble} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='row footer-second w-100'>
          <div className='col-12'>
            <div className='container'>
              <div className='row'>
                <div class="col-10  col-sm-11 ">
                  <p className=' mb-0 footer-second-para'>Copyright 2022 OceanPoker.io</p>
                </div>
                <div className='col-2 col-sm-1 text-center'>
                  <select name="" id="" className='footer-select'>
                    <option value="EN">EN</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>

  )
}

export default Footer