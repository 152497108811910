import React from "react";
import { Image as Images } from "../Images";
import CarouselComponent from "./CarouselComponent";
import {
  imageStyle
} from "./style";
import routes from "../../Route/URLs";

const Carousel = (props) => {
  const { history } = props;
  const handleCarousalRoute = (routePath) => {
    switch (routePath) {
      case routes.FISH_BUFFET:
        return history.push(routes.FISH_BUFFET);
      case routes.GG_CARE_AND_GG_CHEERS:
        return history.push(routes.GG_CARE_AND_GG_CHEERS);
      case routes.MILLION_GIVEAWAY:
        return history.push(routes.MILLION_GIVEAWAY);
      case routes.MILLIONS_TOURNAMENT:
        return history.push(routes.MILLIONS_TOURNAMENT);
      case routes.MONTHLY_MILLION_GIVEAWAY:
        return history.push(routes.MONTHLY_MILLION_GIVEAWAY);
      case routes.WELCOME_BONUS:
        return history.push(routes.WELCOME_BONUS);
      default:
        return history.push(routes.HOME_PAGE);
    }
  };
  return (
    <CarouselComponent
      slides={[
        <img
          src={Images.carousel1}
          onClick={() => handleCarousalRoute(routes.FISH_BUFFET)}
          alt="Image1"
          style={imageStyle}
        />,
        <img
          onClick={() => handleCarousalRoute(routes.WELCOME_BONUS)}
          src={Images.carousel2}
          alt="Image2"
          style={imageStyle}
        />,
        <img
          src={Images.carousel3}
          onClick={() => handleCarousalRoute(routes.GG_CARE_AND_GG_CHEERS)}
          alt="Image3"
          style={imageStyle}
        />,
        <img
          onClick={() => handleCarousalRoute(routes.MILLIONS_TOURNAMENT)}
          src={Images.carousel4}
          alt="Image4"
          style={imageStyle}
        />,
        <img
          onClick={() => handleCarousalRoute(routes.MILLION_GIVEAWAY)}
          src={Images.carousel5}
          alt="Image5"
          style={imageStyle}
        />,
      ]}
    />
  );
};

export default Carousel;
