export const getRoute = (route) => {
  const BASE_ROUTE = process.env.BASE_ROUTE_PREFIX || "/ocean-poker/";
  const fullRoute = BASE_ROUTE ? `${BASE_ROUTE}${route}` : route;
  return fullRoute;
};

const routes = {
  ACCESS_DENIED: getRoute(`access-denied`),
  HOME_PAGE: getRoute(`home-page`),
  GG_CARE_AND_GG_CHEERS: getRoute(`gg-care-&-cheers`),
  WELCOME_BONUS: getRoute(`welcome-bonus`),
  MILLIONS_TOURNAMENT: getRoute(`millions-tournament`),
  MILLION_GIVEAWAY: getRoute(`million-giveaway`),
  FISH_BUFFET: getRoute(`fish-buffet`),
  MONTHLY_MILLION_GIVEAWAY: getRoute(`monthly/million_giveaway`),
};
export default routes;
