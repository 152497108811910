import React from "react";
import BasicLayout from "../../OPBasicLayout";
const WelcomeBonus = (props) => {
  return (
    <BasicLayout>
        <h1 style={{color: "#ffffff", textAlign: "center", marginTop: "5em"}}>Welcome Bonus InProgess....</h1>
    </BasicLayout>
  );
};

export default WelcomeBonus;
