import React from 'react'
import { Image as Images } from './Images'


const ThisMonth = () => {
    let arr = [
        {
            Img: Images.month1,
            title: 'Micro Million$',
            title1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus feugiat mattis eros suscipit facilisis. "
        },
        {
            Img: Images.month2,
            title: 'GG MASTERS',
            title1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus feugiat mattis eros suscipit facilisis. "
        },
        {
            Img: Images.month3,
            title: 'Lorem ipsum',
            title1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus feugiat mattis eros suscipit facilisis. "
        },
    ]
    let data = [
        {
            Img: Images.month4,
            title: 'Lorem ipsum',
            title1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus feugiat mattis eros suscipit facilisis. "
        },
        {
            Img: Images.month5,
            title: 'Lorem ipsum',
            title1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus feugiat mattis eros suscipit facilisis. "
        },
        {
            Img: Images.month6,
            title: 'Lorem ipsum',
            title1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus feugiat mattis eros suscipit facilisis. "
        },
    ]

    return (
        <>
            <div className="container month">
            <hr className='divider' />
            <div>
                <h1 className='text-center month-header'>This Month</h1>
            </div>
                <div className='row  '>
                    {arr.map((e, i) => {
                        return (
                            <div className='col-12 co-sm-6 col-md-4 col-lg-4 col-xl-4 pt-3 pb-3 d-flex justify-content-center align-item-center gap-2'>
                                <div class="card month-card">
                                    <div className='p-0'>
                                        <img src={e.Img} className="card-img-top position-relative" alt="..." width='404px' height='278x'/>
                                        <div className="card-body position-absolute month-card-body bottom-0 ">
                                            <h1 className="card-text  ">{e.title}</h1>
                                            <p className="card-text  ">{e.title1}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='row '>
                    {data.map((e, i) => {
                        return (
                            <div className='col-12 co-sm-6 col-md-4 col-lg-4 col-xl-4 pt-3 pb-3 d-flex justify-content-center align-item-center  gap-2'>
                                <div class="card month-card">
                                    <div className='p-0'>
                                        <img src={e.Img} className="card-img-top " width='404px' height='144px' alt="..." />
                                        <div className="card-body  month-card-body month-custom bottom-0 ">
                                            <h1 className="card-text  ">{e.title}</h1>
                                            <p className="card-text  ">{e.title1}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
                   </>
    )
}

export default ThisMonth