import Award1 from "../../assets/Images/award1.png"
import Award2 from "../../assets/Images/award2.png"
import { Image } from "../Images"
export const awardImageUrl = [
    {
        url: Award1,
        link: "#",
    },
    {
        url: Award2,
        link: "#",
    }
]
export const pokerNetwork = [
    {
        url: Image.cardPoker1,
        heading: "Ocean Poker & GG Poker",
        description: "Ocean Poker operates on the GG Poker Network, which is one of, if not the, largest poker networks in the world.  With such vast amounts of players - you can get a game whenever you want."
    },
    {
        url: Image.cardPoker2,
        heading: "The Home of Poker",
        description: "Together with GG Poker, we will offer the biggest online tournaments, exclusive qualification for live events, massive promotions, and even break records!"
    },
    {
        url: Image.cardPoker3,
        heading: "WSOP",
        description: "GG Poker is the main online partner for the World Series of Poker, and in addition to supporting the live action, is home to the massive WSOP Online.  Ocean Poker enables you to be part of the action."
    }
]