import React from "react";
import { awardImageUrl, pokerNetwork } from "./constant";
import CardComponent from "../Card";
import {
  networkHeading,
  headingContent,
  networkCards,
  awardcards,
  awardImages,
} from "./style";
const CarouselComponent = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={awardcards}>
        {awardImageUrl?.map((image, index) => {
          return (
            <div>
              <img
                alt={index + 1}
                style={awardImages}
                src={image.url}
              />
            </div>
          );
        })}
      </div>
      <div style={networkHeading}>
        <p style={headingContent}>ABOUT OUR POKER NETWORK</p>
      </div>
      <div style={networkCards}>
        {pokerNetwork?.map((poker, index) => {
          return (
            <CardComponent
              index={index}
              url={poker.url}
              heading={poker.heading}
              description={poker.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CarouselComponent;
