/* eslint-disable */
import React, { Suspense, lazy, ComponentType } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import PrivateRouteWithStore from "./PrivateRouteWithStore";
import AccessDenied from "./AccessDenied";
import PageNotFound from "./PageNotFound";
import ErrorBoundary from "./ErrorBoundry";
import routes from "./URLs";
import history from "../utils/history";
import HomePage from "../modules/HomePage";
import GGCareAndCheers from "../modules/GGCareAndCheers";
import FishBuffet from "../modules/FishBuffet";
import MillionsGiveaway from "../modules/MillionGiveaway";
import MillionsTournament from "../modules/MillionsTournament";
import MonthlyMillionsGiveaway from "../modules/MonthlyMillionsGiveaway";
import WelcomeBonus from "../modules/WelcomeBonus";

export const BASE_ROUTE = process.env.BASE_ROUTE_PREFIX || "/ocean-poker/";

const Routes = () => {
  return (
    <Router history={history}>
      <ErrorBoundary>
        <Switch>
          <Route
            exact
            path="/home"
            render={() => {
              return <Redirect to={routes.HOME_PAGE} />;
            }}
          />
          <PrivateRouteWithStore
            exact
            path={routes.HOME_PAGE}
            component={(props) => <HomePage {...props} />}
          />
          <PrivateRouteWithStore
            exact
            path={routes.FISH_BUFFET}
            component={(props) => <FishBuffet {...props} />}
          />
          <PrivateRouteWithStore
            exact
            path={routes.GG_CARE_AND_GG_CHEERS}
            component={(props) => <GGCareAndCheers {...props} />}
          />
          <PrivateRouteWithStore
            exact
            path={routes.MILLION_GIVEAWAY}
            component={(props) => <MillionsGiveaway {...props} />}
          />
          <PrivateRouteWithStore
            exact
            path={routes.MILLIONS_TOURNAMENT}
            component={(props) => <MillionsTournament {...props} />}
          />
          <PrivateRouteWithStore
            exact
            path={routes.MONTHLY_MILLION_GIVEAWAY}
            component={(props) => <MonthlyMillionsGiveaway {...props} />}
          />
          <PrivateRouteWithStore
            exact
            path={routes.WELCOME_BONUS}
            component={(props) => <WelcomeBonus {...props} />}
          />
          <PrivateRouteWithStore
            exact
            path={routes.ACCESS_DENIED}
            component={(props) => <AccessDenied {...props} />}
          />
          <PrivateRouteWithStore
            path="*"
            component={(props) => <PageNotFound {...props} />}
          />
        </Switch>
      </ErrorBoundary>
    </Router>
  );
};

export default Routes;
