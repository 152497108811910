export const cardImage = {
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  width: "350px",
  height: "450px",
};
export const cardStyle = {
  // width: "400px",
  // height: "500px",
  backgroundBlendMode: "lighten, normal",
  backgroundColor: "#000000",
  background: "linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04))",
  // marginBottom: "25px",
};
export const headerTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "20px",
  letterSpacing: "0.0924px",
  color: "#FFFFFF",
};
export const headerDescription = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "15px",
  letterSpacing: "0.05544px",
  color: "#FFFFFF",
};
